<template>
  <div>
    <Button
      _key="btncCostCenterCreate"
      title="Centro de Custo"
      classIcon="fas fa-plus-circle"
      type="primary"
      size="small"
      eventName="costCenterCreate"
    />
    <CostCenterList />
  </div>
</template>

<script>
import CostCenterList from "../../../components/modules/finance/cost-center/CostCenterList.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

export default {
  name: "CostCenterView",
  components: {
    Button,
    CostCenterList,
  },
};
</script>
