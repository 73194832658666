export default class CostCenter {
    constructor() {
        this.id = "";
        this.name = "";
        this.costCenterId = "";
    }
    update(data) {       
        this.id = data.id;
        this.name = data.name;
        this.costCenterId = data.costCenterId;
    }
}