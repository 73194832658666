<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <Button _key="btnBankCreate" title="Banco" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Banco" :width="500" :height="250" v-show="showModal('bankCreateUpdate')">
      <BankCreateUpdate />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import BankCreateUpdate from "../../../components/modules/finance/bank/BankCreateUpdate.vue";

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TypePaymentView",
  components: {
    ViewTemplateConfiguration,
    Button,
    Modal,
    BankCreateUpdate,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/finance/bank/get-all",
        urlDeleteAllApi: "/api/v1/finance/bank/delete",
        headerTable: [
          {
            field: "urlLogo",
            container: "nixloc-bank-logo",
            type: "image",
            classCssBody: "img-bank",
          },
          {
            field: "name",
            title: "Nome",
            type: "link",
            iconSearch: true,
            eventName: "bankUpdate",
          },
          {
            field: "number",
            title: "Número",
            type: "text",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.openModal("bankCreateUpdate");
      this.addEvent({ name: "bankCreate" });
      this.removeLoading(["btnBankCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "bankUpdate") this.openModal("bankCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>
