<template>
  <div>
    <b-row>
      <b-col sm="12">
        <InputText
          title="Nome"
          field="name"
          :formName="formName"
          :required="true"
          :maxLength="40"
          v-model="costCenter.name"
          :markFormDirty="false"
        />
      </b-col>
      <b-col sm="6">
        <RadioGroup
          title="Tipo"
          field="type"
          :formName="formName"
          :options="[
            { text: 'Receita', value: 1 },
            { text: 'Despesa', value: 2 },
          ]"
          v-model="costCenter.type"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button
            _key="btnSaveCostCenter"
            type="success"
            title="Salvar"
            :disabled="!isFormValid(formName)"
            classIcon="fas fa-save"
            size="medium"
            :clicked="saveCostCenter"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import CostCenter from "@/components/modules/finance/cost-center/CostCenter.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CostCenterCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { InputText, RadioGroup, Button },
  data() {
    return {
      formName: "costCenterCreateUpdate",
      costCenter: new CostCenter(),
      urlCreate: "/api/v1/finance/cost-center/create",
      urlUpdate: "/api/v1/finance/cost-center/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "addEvent",
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    saveCostCenter() {
      if (this.costCenter.id) {
        let params = { url: this.urlUpdate, obj: this.costCenter };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.addEvent({ name: "costCenterSaved" });
          this.removeLoading(["btnSaveCostCenter"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.costCenter };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
           this.hideVodal(this.formName);;
          }
          this.addEvent({ name: "costCenterSaved" });
          this.removeLoading(["btnSaveCostCenter"]);
        });
      }
    },
    update(data) {
      this.costCenter = new CostCenter();
      this.costCenter.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "costCenterCreate") {
          this.resetValidation(this.formName);
          this.costCenter = new CostCenter();
        }
        if (event.name == "costCenterUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
