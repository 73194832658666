<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <Button _key="btnBankAccountCreate" title="Conta Bancária" classIcon="fas fa-plus-circle" type="primary"
          size="small" :clicked="create" />
      </div>
    </ViewTemplateConfiguration>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapMutations } from "vuex";

export default {
  name: "BankAccountView",
  components: {
    ViewTemplateConfiguration,
    Button,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/finance/bank-account/get-all",
        urlDeleteAllApi: "/api/v1/finance/bank-account/delete",
        headerTable: [
          {
            field: "urlLogo",
            container: "nixloc-bank-logo",
            type: "image",
            classCssBody: "img-bank",
          },
          {
            field: "fullName",
            title: "Conta",
            type: "link",
            iconSearch: true,
            classCssBody: "center-vertical-bank",
            routeName: "bankAccountUpdate",
          },
          {
            field: "openingBalance",
            title: "Saldo Inicial",
            type: "currency",
            classCssTitle: "text-center",
            classCssBody: "center-vertical-bank text-center",
          },
          {
            field: "accountBalance",
            title: "Saldo Atual",
            type: "currency",
            classCssTitle: "text-center",
            classCssBody: "center-vertical-bank text-center",
          },
          /*   {
               field: "isPluggyConnected",
               title: "Open Finance",
               classCssTitle: "text-center",
               classCssBody: "center-vertical-bank text-center",
               type: "html",
               iconSearch: false,
             },*/
        ],
      },
    };
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "bankAccountCreate",
      });
      this.removeLoading(["btnBankAccountCreate"]);
    },
  },
};
</script>
<style>
.box-user {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 8px;
}

.disconnected {
  background-color: red;
}

.connected {
  background-color: #0A36DB;
}
</style>