<template>
  <div>
    <div class="div-loading">
      <Loading type="line" :center="false" v-show="loading" />
      <b-row>
        <b-col sm="12">
          <TableTotalRecords :totalRecords="content.totalRecords" />
        </b-col>
      </b-row>
    </div>
    <ScrollBar id="chartOfAccounts-rent" :minHeight="400" :maxHeight="800">
      <table class="table table-responsive-xs">
        <thead>
          <tr>
            <th>
              <span class="title-header">Nome</span>
            </th>
            <th class="text-center">
              <span class="title-header">Tipo</span>
            </th>
            <th class="text-center">
              <span class="title-header">Plano de Conta</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="costCenter in content.data" :key="costCenter.id">
          <tr>
            <td>
              <div class="link" @click="updateCostCenter(costCenter)">
                {{ costCenter.name }}
              </div>
            </td>
            <td class="text-center">
              <div v-if="costCenter.typeName == 'Despesa'" class="badge badge-expense td-margin">
                Despesa
              </div>
              <div class="badge badge-revenue td-margin" v-else>Receita</div>
            </td>
            <td class="text-center">
              <div class="total-chart-of-accounts">
                {{ costCenter.totalChartOfAccounts }}
              </div>
            </td>
            <td class="text-right">
              <div class="side-by-side">
                <ButtonSub :itemId="costCenter.id" :urlGet="urlGetApiChartOfAccounts" eventNameShow="showItems"
                  eventNameHide="hideItems" :propsParam="{ any: costCenter.id, noPaginated: true }" v-model="buttonSub" />
                <div class="separator"></div>
              </div>
              <div class="side-by-side">
                <Button :_key="costCenter.id" type="danger" classIcon="fa-solid fa-trash" size="small"
                  :params="costCenter.id" :clicked="confirmDelete" />
              </div>
            </td>
          </tr>
          <td colspan="8" v-if="buttonSub.itemId == costCenter.id">
            <Loading type="line" :center="false" />
          </td>
          <td colspan="8" v-if="showChartOfAccounts(costCenter.id)">
            <table class="table-sub table table-responsive-xs">
              <thead>
                <tr>
                  <th>
                    <span class="title-header">
                      <i class="fa-solid fa-circle-plus add-chart-of-accounts"
                        @click="createChartOfAccounts(costCenter.id)" />
                      Plano de Conta
                    </span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-for="chartOfAccounts in getAllById(costCenter.id)" :key="chartOfAccounts.id">
                <tr>
                  <td class="td-chart-of-accounts" @click="updateChartOfAccounts(chartOfAccounts)">
                    <span class="link">{{ chartOfAccounts.name }}</span>
                  </td>
                  <td>
                    <i class="danger fa-solid fa-trash" @click="deleteChartOfAccounts(chartOfAccounts)"></i>
                  </td>
                </tr>
              </tbody>
              <tbody v-show="getAllById(costCenter.id).length == 0">
                <tr>
                  <td colspan="8">
                    <span class="title">Nenhum registro encontrado!</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tbody>
        <tbody v-show="content.totalRecords == 0">
          <tr>
            <td colspan="8">
              <span class="title">Nenhum registro encontrado!</span>
            </td>
          </tr>
        </tbody>
      </table>
    </ScrollBar>
    <Modal title="Centro de Custo" :width="500" :height="250" v-show="showModal('costCenterCreateUpdate')">
      <CostCenterCreateUpdate />
    </Modal>
    <Modal title="Plano de Conta" :width="500" :height="250" v-show="showModal('chartOfAccountsCreateUpdate')">
      <ChartOfAccountsCreateUpdate :costCenterId="costCenterId" />
    </Modal>
    <Modal title="Tem certeza que deseja excluir?" :width="500" :height="250" v-show="showModal('confirm')">
      <Confirmation :isModal="false" type="danger" :confirmed="deleteCostCenter" />
    </Modal>
  </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import ButtonSub from "@nixweb/nixloc-ui/src/component/forms/ButtonSub.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import Dropdown from "@nixweb/nixloc-ui/src/component/forms/Dropdown.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";

import CostCenterCreateUpdate from "./CostCenterCreateUpdate.vue";
import ChartOfAccountsCreateUpdate from "../chart-of-accounts/ChartOfAccountsCreateUpdate.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "CostCenterList",
  components: {
    Button,
    Loading,
    ScrollBar,
    Dropdown,
    TableTotalRecords,
    ButtonSub,
    Modal,
    Confirmation,
    ChartOfAccountsCreateUpdate,
    CostCenterCreateUpdate,
  },
  data() {
    return {
      urlGetApi: "/api/v1/finance/cost-center/get-all",
      urlDeleteApi: "/api/v1/finance/cost-center/delete",
      urlGetApiChartOfAccounts: "/api/v1/finance/chart-of-accounts/get-all",
      urlDeleteApiChartOfAccounts: "/api/v1/finance/chart-of-accounts/delete",
      data: [],
      loading: true,
      content: {
        data: [],
        totalRecords: 0,
      },
      baseParams: {
        search: undefined,
        filter: "contains",
        currentPage: 1,
        totalPerPage: 20,
      },
      itemsChartOfAccounts: {
        ids: [],
        data: [],
      },
      buttonSub: {},
      costCenterId: "",
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi", "deleteAllApi"]),
    ...mapMutations("generic", [
      "addEvent",
      "openModal",
      "hideModal",
      "removeLoading",
    ]),
    getAll() {
      this.loading = true;
      let params = {
        url: this.urlGetApi,
        obj: {
          ...this.baseParams,
          noPaginated: true,
        },
      };
      this.getApi(params).then((response) => {
        this.content.data = response.content.data;
        this.content.totalRecords = response.content.totalRecords;
        this.loading = false;
      });
    },
    getAllChartOfAccounts(costCenterId) {
      let params = {
        url: this.urlGetApiChartOfAccounts,
        obj: { any: costCenterId },
      };
      this.getApi(params).then((response) => {
        this.itemsChartOfAccounts.data = response.content.data;
      });
    },
    updateCostCenter(costCenter) {
      this.addEvent({ name: "costCenterUpdate", data: costCenter });
    },
    createChartOfAccounts(costCenterId) {
      this.costCenterId = costCenterId;
      this.addEvent({ name: "chartOfAccountsCreate" });
      this.openModal("chartOfAccountsCreateUpdate");
    },
    updateChartOfAccounts(costCenter) {
      this.addEvent({ name: "chartOfAccountsUpdate", data: costCenter });
      this.openModal("chartOfAccountsCreateUpdate");
    },
    deleteChartOfAccounts(chartOfAccounts) {
      let params = {
        url: this.urlDeleteApiChartOfAccounts,
        selected: [chartOfAccounts.id],
      };
      this.deleteAllApi(params).then((reponse) => {
        if (reponse.success) {
          this.getAllChartOfAccounts(chartOfAccounts.costCenterId);
        }
      });
    },
    confirmDelete(costCenterId) {
      this.costCenterId = costCenterId;
      this.removeLoading([costCenterId]);
      this.openModal("confirm");
    },
    deleteCostCenter() {
      let params = {
        url: this.urlDeleteApi,
        selected: [this.costCenterId],
      };
      this.deleteAllApi(params).then((reponse) => {
        if (reponse.success) {
          this.getAll();
          this.hideModal("confirm");
          this.removeLoading(["confirm"]);
        }
      });
    },
    showChartOfAccounts(itemId) {
      return this.itemsChartOfAccounts.ids.includes(itemId);
    },
    getAllById(costCenterId) {
      let filter = this.itemsChartOfAccounts.data.filter(
        (x) => x.costCenterId == costCenterId
      );
      return filter;
    },
  },
  watch: {
    event: {
      handler(event) {
        switch (event.name) {
          case "showItems":
            let self = this;
            setTimeout(function () {
              self.itemsChartOfAccounts.ids.push(event.data.itemId);
            }, 400);

            event.data.data.forEach((costCenter) => {
              self.itemsChartOfAccounts.data.push(costCenter);
            });
            break;

          case "hideItems":
            let filterData = this.itemsChartOfAccounts.data.filter(
              (x) => x.costCenterId != event.data.itemId
            );
            this.itemsChartOfAccounts.data = filterData;

            let filter = this.itemsChartOfAccounts.ids.filter(
              (x) => x != event.data.itemId
            );
            this.itemsChartOfAccounts.ids = filter;
            break;

          case "costCenterSaved":
            this.getAll();
            break;

          case "costCenterCreate":
            this.openModal("costCenterCreateUpdate");
            this.removeLoading(["btncCostCenterCreate"]);
            break;

          case "costCenterUpdate":
            this.openModal("costCenterCreateUpdate");
            break;

          case "chartOfAccountsSaved":
            this.getAll();
            this.getAllChartOfAccounts(event.data.costCenterId);
            break;

          default:
            break;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.table-sub {
  background-color: #f3f3f3;
}

.td-description {
  max-width: 300px !important;
  word-wrap: break-word !important;
  font-size: 14px;
  color: #757d8c;
  font-style: italic;
}

.td-chart-of-accounts {
  width: 400px;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.group {
  background-color: #fafafc;
  font-size: 14px;
  font-weight: 400;
}

.title-group {
  font-size: 14px;
  margin-left: 10px;
}

.div-warning {
  margin-bottom: 20px;
}

.td-margin {
  margin-top: 10px;
}

.badge {
  font-size: 13px !important;
  margin-left: 5px;
  border-radius: 10px !important;
  padding: 8px;
  color: white;
}

.badge-revenue {
  background-color: darkblue;
}

.badge-expense {
  background-color: red;
}

.add-chart-of-accounts {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
  color: #577696 !important;
}

.link {
  margin-top: 8px;
  color: #3f529b;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.div-loading {
  margin-top: 10px;
}

.total-chart-of-accounts {
  margin-top: 8px;
}

.danger {
  color: #f0134d;
  cursor: pointer;
}

.separator {
  height: 8px;
}
</style>
